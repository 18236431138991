export const isValidJSON = (value) => {
  try {
    return !!JSON.parse(value);
  } catch (e) {
    return false;
  }
};

export const safeJSONObjParse = (string) => (isValidJSON(string) ? JSON.parse(string) : {}) || {};

export const isValidToken = (token) => token && !['undefined', 'null'].includes(token);

export const deepClone = (value) => JSON.parse(JSON.stringify(value));
