<template>
  <div class="survey" v-if="model">
    <!-- SurveyJS Component -->
    <survey :survey="model" />
  </div>
</template>

<script>
import * as Survey from 'survey-vue';

import { safeJSONObjParse } from '../utils/validation';
import Errors from '../constants/errors';
import Messages from '../constants/messages';
import Request from '../utils/fetch';
import 'survey-vue/modern.css';

Survey.StylesManager.applyTheme('modern');

const defaultCompletedHtml = `
  <h3>Thank You!</h3>
  <br />
  <h3>Your form has been submitted.</h3>
`;

export default {
  name: 'SurveyForm',
  props: {
    templateId: {
      type: String,
      required: true,
    },
    // SurveyJS Json Schema
    schema: {
      type: [Object, String],
      required: true,
    },
    allowSave: Boolean,
  },
  data() {
    return {
      formId: null,
      model: null,
      isSubmitted: false,
    };
  },
  watch: {
    schema: {
      immediate: true,
      handler(newSchema) {
        const jsonSchema = typeof newSchema === 'string' ? safeJSONObjParse(newSchema) : newSchema;
        jsonSchema.completedHtml = jsonSchema.completedHtml || defaultCompletedHtml;
        this.model = new Survey.Model(jsonSchema);
        this.model.onCompleting.add(this.onSubmit);
      },
    },
  },
  mounted() {
    if (this.allowSave) {
      const buttonNav = document.querySelector('.sv_nav');
      const saveButton = document.createElement('button');
      saveButton.appendChild(document.createTextNode('Save'));
      saveButton.className = 'sv_save_btn';
      saveButton.addEventListener('click', () => this.save(this.model.data));
      buttonNav.appendChild(saveButton);
      // Check if form has previously been started
      this.getSavedForm()
        .then((startedForm) => {
          if (startedForm) {
            this.formId = startedForm.id;
            this.model.data = safeJSONObjParse(startedForm.data);
          }
        })
        .catch((e) => {
          console.error(e);
          this.$store.commit('set', { errorMessage: Errors.GET_SAVED_FORM });
        });
    }
  },
  methods: {
    async getSavedForm() {
      const { providerId } = this.$store.state.user.info;
      const memberId = this.$store.state.user.info.id;
      const incompleteForms = await Request.get(
        `/providers/${providerId}/members/${memberId}/forms/incomplete`
      );
      return incompleteForms.find((form) => form.templateId === this.templateId);
    },
    async createForm(data) {
      const { providerId, id } = this.$store.state.user.info;
      const form = await Request.post(`/providers/${providerId}/forms`, {
        templateId: this.templateId,
        memberId: id,
        data: JSON.stringify(data),
      });
      this.formId = form.id;
    },
    async save(data) {
      try {
        if (!this.allowSave) throw Errors.FORM_SAVE_DISABLED;
        if (this.formId) {
          const { providerId } = this.$store.state.user.info;
          await Request.put(`/providers/${providerId}/forms/${this.formId}`, {
            data: JSON.stringify(data),
          });
        } else {
          await this.createForm(data);
        }
        this.$store.dispatch('setNotification', {
          color: 'success',
          text: Messages.SAVE_SUCCESS,
          timeout: 3000,
        });
      } catch (e) {
        this.$store.dispatch('setNotification', {
          color: 'error',
          text: e,
          timeout: 3000,
        });
      }
    },
    async submit(data) {
      const { providerId } = this.$store.state.user.info;
      await Request.post(`/providers/${providerId}/forms/${this.formId}`, {
        data: JSON.stringify(data),
      });
    },
    onSubmit(surveyResult, options) {
      if (!this.isSubmitted) {
        // Setting this options disables the SurveyJS success page from showing immediately
        // eslint-disable-next-line no-param-reassign
        options.allowComplete = false;
        this.submit(surveyResult.data)
          .then(() => {
            this.isSubmitted = true;
            // Triggering onCompleting again after submission success
            // eslint-disable-next-line no-param-reassign
            options.allowComplete = true;
            this.model.doComplete();
          })
          .catch((e) => {
            this.$store.dispatch('setNotification', {
              color: 'error',
              text: e,
              timeout: 3000,
            });
          });
      }
    },
  },
};
</script>

<style>
.sv_save_btn {
  float: right;
  margin-right: 8px;
  background-color: #ccc !important;
  color: black !important;
}

.sv_save_btn:hover {
  background-color: #999 !important;
}
</style>
