class Provider {
  constructor({ firstName, lastName, id, ...rest }) {
    this.name = `${firstName} ${lastName}`;
    this.id = id;

    Object.entries(rest).forEach(([key, value]) => {
      this[key] = value;
    });
  }
}

export default Provider;
