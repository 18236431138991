import { ApolloLink } from 'apollo-link';
import { onError } from 'apollo-link-error';
import { createPersistedQueryLink } from 'apollo-link-persisted-queries';

import { AUTH_TOKEN, VUEX_STORE } from './constants/storage';
import { ERROR_CODES } from './constants/errors';
import router from './router';

export const removeData = () => {
  localStorage.removeItem(AUTH_TOKEN);
  localStorage.removeItem(VUEX_STORE);
};

const httpLink = new ApolloLink((operation, forward) => {
  // add the authorization to the headers
  const token = localStorage.getItem(AUTH_TOKEN);
  const validToken = token && !['undefined', 'null'].includes(token);
  const headers = {};

  if (validToken) headers.authorization = `Bearer ${token}`;

  operation.setContext({
    headers,
  });

  return forward(operation);
});

export const sessionExpired = () => {
  removeData();
  router.push({ name: 'Login', query: { reset: true } });
};

const errorLink = onError(({ networkError }) => {
  if (networkError && networkError.statusCode === ERROR_CODES.unauthorized) {
    sessionExpired();
  }
});

export const authMiddleware = createPersistedQueryLink().concat(errorLink).concat(httpLink);
