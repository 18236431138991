import Vue from 'vue';
import Router from 'vue-router';

import Login from './views/Login.vue';
import PublicSurvey from './views/PublicSurvey.vue';
import SelectProvider from './views/SelectProvider.vue';
import Survey from './views/Survey.vue';

Vue.use(Router);

const router = new Router({
  mode: 'history',
  routes: [
    {
      path: '/select-provider',
      name: 'SelectProvider',
      component: SelectProvider,
    },
    {
      path: '/private/:tenantUrl/:templateId',
      name: 'Survey',
      component: Survey,
    },
    // For use with public form submission and public account creation
    {
      path: '/public/:templateId',
      name: 'PublicSurvey',
      component: PublicSurvey,
    },
    {
      path: '/',
      name: 'Login',
      component: Login,
    },
  ],
});

export default router;
