import Vue from 'vue';
import VuexPersist from 'vuex-persist';
import Vuex from 'vuex';
import createLogger from 'vuex/dist/logger';

import { apolloClient } from './vue-apollo';
import { VUEX_STORE, EXCLUDE_KEYS } from './constants/storage';
import GET_CURRENT_USER from './graphql/Query/GetCurrentUser.gql';

Vue.use(Vuex);

const vuexLocalStorage = new VuexPersist({
  key: VUEX_STORE,
  storage: window.localStorage,
  reducer: (state) => {
    const copy = { ...state };

    EXCLUDE_KEYS.forEach((k) => {
      delete copy[k];
    });

    return copy;
  },
});

const defaultNotification = {
  color: '',
  showing: false,
  text: '',
  timeout: -1,
};

export default new Vuex.Store({
  state: {
    redirect: null,
    providerId: null,
    notification: defaultNotification,
    user: {
      info: {},
    },
  },
  mutations: {
    set(state, updates) {
      Object.assign(state, updates);
    },
    restoreSession(state) {
      state.providerId = null;
      state.user.info = {};
    },
    saveUser(state, value) {
      state.user.info = { ...state.user.info, ...value };
    },
  },
  actions: {
    async updateProviderId({ commit }, providerId) {
      commit('set', {
        providerId,
      });
    },
    setNotification({ commit }, notification) {
      commit('set', {
        notification: {
          ...defaultNotification,
          ...notification,
          showing: true,
        },
      });
    },
    async getAndSaveUser({ commit }) {
      const { data } = await apolloClient.query({
        query: GET_CURRENT_USER,
        fetchPolicy: 'no-cache',
      });

      const { endTime, startTime, timeZone } = data.getCurrentUser.metaData;

      // Save user settings
      const settings = {};

      if (endTime) settings.endTime = endTime;
      if (startTime) settings.startTime = startTime;
      if (timeZone) settings.timezone = timeZone;

      // Save user info
      const info = { ...data.getCurrentUser };
      delete info.metaData;
      if (info.primaryRole === 'schedule-admin') info.isAdmin = true;

      await commit('saveUser', info);
    },
  },
  plugins: [
    vuexLocalStorage.plugin,
    ...(process.env.NODE_ENV === 'development' ? [createLogger()] : []),
  ],
});
