<template>
  <div>
    <SurveyForm
      v-if="surveySchema"
      :schema="surveySchema"
      :template-id="templateId"
      :allow-save="allowSave"
    />
    <p v-else>Loading...</p>
  </div>
</template>

<style>
.sv-completedpage {
  background: transparent !important;
}
</style>

<script>
import errors from '../constants/errors';
import Request from '../utils/fetch';
import SurveyForm from '../components/SurveyForm.vue';

export default {
  name: 'SurveyView',
  components: {
    SurveyForm,
  },
  data: () => ({
    surveySchema: null,
    templateId: null,
    allowSave: false,
  }),
  mounted() {
    const { templateId, tenantUrl } = this.$route.params; // Public/internal templateId
    const { providerId, id } = this.$store.state.user.info;

    this.$store.commit('set', { redirect: this.$route, tenantUrl });

    if (!providerId || !id) return;

    Request.get(`/providers/${providerId}/templates/${templateId}`)
      .then((data) => {
        this.templateId = data.id; // internal templateId
        this.surveySchema = data.schema;
        this.allowSave = data.allowSave;
      })
      .catch((e) => {
        console.error(e);
        this.$store.dispatch('setNotification', {
          color: 'error',
          text: errors.GET_FORM,
        });
      });
  },
};
</script>
