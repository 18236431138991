<template>
  <v-form class="error-container login" @submit.prevent="login" :disabled="disabled">
    <v-card class="my-12 mx-auto text-center primary--text" width="600" outlined>
      <h1 class="text-capitalize mb-5">
        <figure>
          <img :src="logo" alt="Sentry Admin" class="logo" />
        </figure>
      </h1>
      <v-card-text class="error-text">
        <v-text-field type="email" name="email" outlined label="Email" v-model="email" required>
        </v-text-field>
        <v-text-field
          label="Password"
          name="password"
          outlined
          required
          type="password"
          v-model="password"
        ></v-text-field>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <div>
          <template v-if="errorMessage">
            <small class="error--text">{{ errorMessage }}</small>
            <br />
          </template>
          <v-btn type="submit" color="primary" :disabled="!email || !password || disabled">
            Login
          </v-btn>
        </div>
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>
  </v-form>
</template>
<style>
.logo {
  max-width: 200px;
}
</style>
<script>
import to from 'await-to-js';

import { AUTH_TOKEN } from '../constants/storage';
import LOGIN from '../graphql/Mutation/Login.gql';
import logo from '../images/logo-full.png';

export default {
  name: 'Login',
  data: () => ({
    disabled: false,
    email: '',
    errorMessage: '',
    logo,
    password: '',
    visible: false,
  }),
  methods: {
    async login() {
      this.disabled = true;
      this.errorMessage = '';
      const { redirect, tenantUrl } = this.$store.state;

      const [errors, query] = await to(
        this.$apollo.mutate({
          mutation: LOGIN,
          variables: {
            email: this.email,
            password: this.password,
            tenantUrl,
          },
        })
      );

      if (errors || query.errors) {
        this.errorMessage = errors.login;
        this.disabled = false;
        return;
      }

      const token = query.data.login;

      localStorage.setItem(AUTH_TOKEN, token);
      await this.$store.dispatch('getAndSaveUser');

      if (redirect) {
        this.$store.commit('set', { redirect: null });
        this.$router.push(redirect);
      } else {
        this.$store.dispatch('setNotification', {
          color: 'success',
          text: 'Logged in. Go to your form link.',
          timeout: 3000,
        });
      }
    },
  },
  computed: {
    userInfo() {
      return this.$store.state.user.info;
    },
  },
  mounted() {
    localStorage.removeItem(AUTH_TOKEN);
  },
};
</script>
