export const BRAND_COLORS = {
  accent: '#38dfff',
  accent2: '#b7f5fb',
  cta: '#48d04c',
  deepBlue: '#050a30',
  emblems: '#2b8aff',
  lightBlue: '#34b6f8',
  lightTeal: '#9bd4e4',
};

export const CALENDAR_SCHEME = {
  cancel: '#EF5350',
  completed: BRAND_COLORS.cta,
  default: '#546E7A',
  pending: BRAND_COLORS.lightBlue,
};

export const LIGHT_THEME = {
  accent: BRAND_COLORS.deepBlue,
  error: CALENDAR_SCHEME.cancel,
  info: BRAND_COLORS.lightBlue,
  primary: BRAND_COLORS.emblems,
  secondary: BRAND_COLORS.accent,
  success: BRAND_COLORS.cta,
  warning: '#FFC107',
};
