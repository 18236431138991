export const ERROR_CODES = {
  unauthorized: 401,
  notFound: 404,
};

export const COMPLETE_ERRORS = {
  [ERROR_CODES.notFound]: {
    icon: 'magnify',
    desc: 'The page you were looking was not found.',
  },
  [ERROR_CODES.unauthorized]: {
    icon: 'cancel',
    desc: "You don't have the permissions.",
  },
  auth: {
    icon: 'help',
    desc: 'Try logging out first.',
  },
  default: {
    desc: 'Something went wrong, try again.',
  },
};

const errors = {
  FORM_SAVE_DISABLED: 'Saving form submission is disabled.',
  GET_SAVED_FORM: "We weren't able to retrieve any saved form submissions.",
  SAVE: 'Failed to save. An unknown error occured. Please contact your provider.',
  SUBMIT: 'Submission Failed. An unknown error occured. Please contact your provider.',
  UNKNOWN: 'An unknown error occured. Please contact your provider.',
  GET_FORM:
    "We couldn't find the form you were looking for. Please contact your provider for support.",
};

export default errors;
