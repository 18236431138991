<template>
  <div>
    <PublicSurveyForm
      v-if="surveySchema"
      :schema="surveySchema"
      :template-id="templateId"
      :allow-save="allowSave"
    />
    <p v-else>Loading...</p>
  </div>
</template>

<script>
import errors from '../constants/errors';
import PublicSurveyForm from '../components/PublicSurveyForm.vue';
import Request from '../utils/fetch';

export default {
  name: 'PublicSurveyView',
  components: {
    PublicSurveyForm,
  },
  data: () => ({
    surveySchema: null,
    templateId: null,
    allowSave: false,
  }),
  mounted() {
    const { providerId } = this.$route.query;
    const { templateId } = this.$route.params; // Public/internal templateId

    if (!providerId || !templateId) {
      this.$router.push({ name: 'Login' });
      this.$store.dispatch('setNotification', {
        color: 'error',
        text: "You don't have the necessary info to use this.",
        timeout: 3000,
      });
      return;
    }

    // obtain form template
    Request.get(`/providers/${providerId}/publicTemplates/${templateId}`)
      .then((data) => {
        this.templateId = data.id; // internal templateId
        this.surveySchema = data.schema;
        this.allowSave = data.allowSave;
      })
      .catch((e) => {
        console.error(e);
        this.$store.dispatch('setNotification', {
          color: 'error',
          text: errors.GET_FORM,
        });
      });
  },
};
</script>
