<template>
  <div class="survey">
    <!-- SurveyJS Component -->
    <survey :survey="model" />
  </div>
</template>

<script>
import * as Survey from 'survey-vue';

import { AUTH_TOKEN } from '../constants/storage';
import { safeJSONObjParse } from '../utils/validation';
import Errors from '../constants/errors';
import Messages from '../constants/messages';
import Request from '../utils/fetch';

const defaultCompletedHtml = `
  <h3>Thank You!</h3>
  <br />
  <h3>Your form has been submitted.</h3>
`;

export default {
  name: 'SurveyForm',
  props: {
    templateId: {
      type: String,
      required: true,
    },
    // SurveyJS Json Schema
    schema: {
      type: [Object, String],
      required: true,
    },
    allowSave: Boolean,
  },
  data() {
    return {
      formId: null,
      model: null,
      isSubmitted: false,
    };
  },
  watch: {
    schema: {
      immediate: true,
      handler(newSchema) {
        const jsonSchema = typeof newSchema === 'string' ? safeJSONObjParse(newSchema) : newSchema;
        jsonSchema.completedHtml = jsonSchema.completedHtml || defaultCompletedHtml;
        this.model = new Survey.Model(jsonSchema);
        this.model.onCompleting.add(this.onSubmit);
      },
    },
  },
  mounted() {
    localStorage.removeItem(AUTH_TOKEN);

    // TODO may have to remove saved form functionality because of new user
    if (this.allowSave) {
      const buttonNav = document.querySelector('.sv_nav');
      const saveButton = document.createElement('button');
      saveButton.appendChild(document.createTextNode('Save'));
      saveButton.className = 'sv_save_btn';
      saveButton.addEventListener('click', () => this.save(this.model.data));
      buttonNav.appendChild(saveButton);
    }
  },
  methods: {
    // TODO: may remove because of new user
    async getSavedForm() {
      const { providerId } = this.$route.query;
      const memberId = this.$store.state.user.info.id;
      const incompleteForms = await Request.get(
        `/providers/${providerId}/members/${memberId}/forms/incomplete`
      );
      return incompleteForms.find((form) => form.templateId === this.templateId);
    },
    async createForm(data) {
      try {
        const { providerId, memberId } = this.$route.query;
        // Create form submission
        // TODO create member and set member ID
        await Request.post(`/providers/${providerId}/publicForms`, {
          templateId: this.templateId,
          memberId,
          data: JSON.stringify(data),
        })
          .then((form) => {
            this.formId = form.id;
          })
          .catch(() => {
            throw Errors.UNKNOWN_ERROR;
          });
      } catch (e) {
        this.$store.dispatch('setNotification', {
          color: 'error',
          text: e,
          timeout: 3000,
        });
      }
    },
    // TODO: may remove because of new user
    async save(data) {
      try {
        if (!this.allowSave) throw Errors.FORM_SAVE_DISABLED;
        if (this.formId) {
          const { providerId } = this.$route.query;
          await Request.put(`/providers/${providerId}/forms/${this.formId}`, {
            data: JSON.stringify(data),
          }).catch((e) => {
            console.error(e);
            throw Errors.SAVE_ERROR;
          });
        } else {
          await this.createForm(data).catch((e) => {
            console.error(e);
            throw Errors.SAVE_ERROR;
          });
        }
        this.$store.dispatch('setNotification', {
          color: 'success',
          text: Messages.SAVE_SUCCESS,
          timeout: 3000,
        });
      } catch (e) {
        this.$store.dispatch('setNotification', {
          color: 'error',
          text: e,
          timeout: 3000,
        });
      }
    },
    async onSubmit(surveyResult, options) {
      if (!this.isSubmitted) {
        // Setting this options disables the SurveyJS success page from showing immediately
        // eslint-disable-next-line no-param-reassign
        options.allowComplete = false;
        await this.createForm(surveyResult.data)
          .then(() => {
            this.isSubmitted = true;
            // Triggering onCompleting again after submission success
            // eslint-disable-next-line no-param-reassign
            options.allowComplete = true;
            this.model.doComplete();
          })
          .catch((e) => {
            this.$store.dispatch('setNotification', {
              color: 'error',
              text: e,
              timeout: 3000,
            });
          });
      }
    },
  },
};
</script>

<style>
.sv_save_btn {
  float: right;
  margin-right: 8px;
  background-color: #ccc !important;
  color: black !important;
}

.sv_save_btn:hover {
  background-color: #999 !important;
}
</style>
