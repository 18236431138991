import fetchIntercept from 'fetch-intercept';

import { ERROR_CODES } from '../constants/errors';
import { removeData } from '../auth';
import router from '../router';

fetchIntercept.register({
  async response(response) {
    if (!response.ok && response.status === ERROR_CODES.unauthorized) {
      removeData();
      router.push({ name: 'Login', query: { reset: true } });
      return response;
    }

    // Modify the reponse object
    return response;
  },
});
