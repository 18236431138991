<template>
  <v-app>
    <notification-snack></notification-snack>
    <v-main>
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
import NotificationSnack from './components/NotificationSnack.vue';
import { WHITELIST_LOGIN } from './constants/page';

export default {
  name: 'App',
  components: {
    NotificationSnack,
  },
  watch: {
    $route(route) {
      this.routeGuard(route);
    },
  },
  mounted() {
    this.routeGuard(this.$route);
  },
  computed: {
    isLoggedIn() {
      return this.$store.state.user.info.id;
    },
  },
  methods: {
    routeGuard({ name, query, fullPath }) {
      const { providerId, id } = this.$store.state.user.info;

      if (query.reset) {
        this.$router.push({ name: 'Login' });
        this.$store.commit('restoreSession');
        this.$store.dispatch('setNotification', {
          color: 'error',
          text: 'Your session has expired. Log in again.',
          timeout: 3000,
        });
        return;
      }

      if (!id && !WHITELIST_LOGIN.includes(name)) {
        this.$store.commit('set', {
          redirect: fullPath,
        });
        this.$router.push({ name: 'Login' });
        return;
      }

      if (id && !providerId) {
        this.$router.history.push({ name: 'SelectProvider' });
      }
    },
  },
};
</script>

<style>
body,
.v-application {
  font-family: 'Montserrat', sans-serif !important;
}
</style>
