import { AUTH_TOKEN } from '../constants/storage';
import { ERROR_CODES } from '../constants/errors';
import { FORMS_API } from '../constants/env';
import { sessionExpired } from '../auth';

const fetchJson = async (route, options = {}) => {
  const parsedOptions = {
    ...options,
    headers: {
      'Content-Type': 'application/json',
    },
  };

  const authToken = localStorage.getItem(AUTH_TOKEN);

  if (authToken) {
    parsedOptions.headers.Authorization = `Bearer ${authToken}`;
  }

  const response = await fetch(`${FORMS_API}${route}`, parsedOptions);
  const data = await response.json();

  if (!response.ok) {
    if (response.status === ERROR_CODES.unauthorized) {
      sessionExpired();
    }
    throw new Error(data && data.message);
  }

  return data;
};

const fetchPost = async (route, body, options = {}) => {
  const value = await fetchJson(route, {
    method: 'post',
    body: JSON.stringify(body),
    ...options,
  });

  return value;
};

const fetchPut = async (route, body, options = {}) => {
  const value = await fetchJson(route, {
    method: 'put',
    body: JSON.stringify(body),
    ...options,
  });

  return value;
};

const request = {
  get: fetchJson,
  post: fetchPost,
  put: fetchPut,
};

export default request;
