import './utils/fetch-intercept';
import 'survey-vue/survey.min.css';

import Vue from 'vue';
import { createProvider } from './vue-apollo';

import App from './App.vue';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';

Vue.config.productionTip = false;

new Vue({
  apolloProvider: createProvider(),
  render: (h) => h(App),
  router,
  store,
  vuetify,
}).$mount('#app');
