import Vue from 'vue';
import VueApollo from 'vue-apollo';
import { createApolloClient, restartWebsockets } from 'vue-cli-plugin-apollo/graphql-client';

import { AUTH_TOKEN } from './constants/storage';
import { GATEWAY_HTTP } from './constants/env';

// Install the vue plugin
Vue.use(VueApollo);

// Http endpoint
const httpEndpoint = `${GATEWAY_HTTP}/graphql`;

// Config
const defaultOptions = {
  httpEndpoint,
  persisting: false,
  ssr: false,
  tokenName: AUTH_TOKEN,
  websocketsOnly: false,
  wsEndpoint: null,
};

const apolloClientService = createApolloClient(defaultOptions);
export const { apolloClient } = apolloClientService;

// Call this in the Vue app file
export function createProvider() {
  // Create apollo client
  apolloClient.wsClient = apolloClientService.wsClient;

  // Create vue apollo provider
  const apolloProvider = new VueApollo({
    defaultClient: apolloClient,
    defaultOptions: {
      $query: {
        fetchPolicy: 'cache-and-network',
        errorPolicy: 'all',
      },
      $mutate: {
        errorPolicy: 'all',
      },
    },
    errorHandler(error) {
      // eslint-disable-next-line no-console
      console.log(
        '%cError',
        'background: red; color: white; padding: 2px 4px; border-radius: 3px; font-weight: bold;',
        error.message
      );
    },
  });

  return apolloProvider;
}

// Manually call this when user log in
export async function onLogin(token) {
  localStorage.setItem(AUTH_TOKEN, token);
  if (apolloClient.wsClient) restartWebsockets(apolloClient.wsClient);
  try {
    await apolloClient.resetStore();
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log('%cError on cache reset (login)', 'color: orange;', e.message);
  }
}

// Manually call this when user log out
export async function onLogout() {
  if (apolloClient.wsClient) restartWebsockets(apolloClient.wsClient);
  try {
    await apolloClient.resetStore();
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log('%cError on cache reset (logout)', 'color: orange;', e.message);
  }
}
